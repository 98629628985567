import React, { useEffect, useState } from "react";
import { get } from "util/requests";
import styles from "./history_bar.module.css";
import moment from "moment-timezone";
import { RefreshIcon, CloseIcon, FilterIcon } from "ui/icons";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const render_data_value = (type, payload) => {
  if (["radio", "select"].includes(type)) {
    return (
      <div>
        Selected: <strong>{payload?.currentValueDisplay}</strong>{" "}
      </div>
    );
  } else if (
    ["decimal", "time", "int", "text", "date", "year"].includes(type)
  ) {
    return (
      <div>
        Value: <strong>{payload?.value}</strong>
      </div>
    );
  } else if (["datetime"].includes(type)) {
    return (
      <div>
        Value:{" "}
        <strong>
          {payload?.value} {payload?.value2}
        </strong>
      </div>
    );
  } else if (["blood_pressure"].includes(type)) {
    return (
      <div>
        Value:{" "}
        <strong>
          {payload?.value} / {payload?.value2}
        </strong>
      </div>
    );
  } else if (["blood_test_result"].includes(type)) {
    return (
      <div>
        Value:{" "}
        {[payload?.value, payload?.value2, payload?.value3, payload.value4]
          .filter((p) => p)
          .join(" | ")}
      </div>
    );
  } else if (["weight"].includes(type)) {
    return (
      <div>
        Value:{" "}
        {`${payload.value} ${payload.value2} ${payload.value3 ? `(${payload.value3})` : ""}`}
      </div>
    );
  }
};

const type_render = (log, data) => {
  if (log.action === "data_change") {
    return (
      <React.Fragment>
        <div>
          Field: <strong>{log.crf_field_id}</strong>
        </div>
        <div>
          Type: <strong>{data.field_type}</strong> - data_change
        </div>
      </React.Fragment>
    );
  } else if (["crf_data_status", "crf_signoff_status"].includes(log.action)) {
    return (
      <React.Fragment>
        <div>Type: {log.action}</div>
        <div>
          Status: <strong>{data.status}</strong>
        </div>
        {data.reason ? (
          <div>
            Reason: <strong>{data.reason}</strong>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
};
const render_log = (log) => {
  let data = JSON.parse(log.data);
  return (
    <div className={styles.log_record}>
      {type_render(log, data)}
      {render_data_value(data.field_type, data.payload)}
      <div>
        User: {log.first_name} {log.last_name}
      </div>
      <div>
        {`${moment(log.created_on).format("YYYY-MM-DD HH:mm:ss")} (${moment.tz(moment.tz.guess()).zoneAbbr()})`}
      </div>
    </div>
  );
};

export default function HistoryBar(props) {
  const {
    subject_id,
    crf_id,
    historyFilters,
    setHistoryFilters,
    segment_height,
  } = props;

  const [logs, setLogs] = useState(null);
  const [showFilterBar, setShowFilterBar] = useState(false);
  const [showHistory, setShowHistory] = useState(false);

  const fetch_data = (historyFilters) => {
    setShowHistory(true);
    get(
      `/audit/logs?user=&subject_id=${subject_id}&crf_id=${crf_id}&crf_field_id=${historyFilters.field_id || ""}&study_id=1&action=${historyFilters.action || ""}&user_id=${![null, undefined].includes(historyFilters.user?.id) ? `${historyFilters.user?.id}` : ""}`,
    ).then((ret) => {
      setLogs(ret.data);
    });
  };

  useEffect(() => {
    if (showHistory) {
      fetch_data({});
    }
    setShowFilterBar(false);
  }, [subject_id, crf_id]);

  useEffect(() => {
    if (Object.keys(historyFilters).length || showHistory) {
      setLogs(null);
      fetch_data(historyFilters);
    }
  }, [historyFilters]);

  const toggleFilterBar = () => setShowFilterBar(!showFilterBar);
  const closeHistory = () => {
    setShowHistory(false);
    setHistoryFilters({});
    setLogs(null);
  };

  if (!showHistory) return null;

  let users = new Set();
  logs?.rows?.forEach((r) => users.add(r.user_id));
  users = Array.from(users).map((u) => {
    let label = logs?.rows?.find((r) => r.user_id === u)
      ? `${logs?.rows?.find((r) => r.user_id === u).first_name} ${logs?.rows?.find((r) => r.user_id === u).last_name}`
      : null;

    return { value: { id: u, name: label }, label };
  });

  let actions = new Set();
  logs?.rows?.forEach((r) => actions.add(r.action));
  actions = Array.from(actions);

  return (
    <div className={styles.history_bar}>
      <div className={styles.history_header}>
        <div className={styles.history_title}>
          <div>Change History</div>
          <div className={styles.menu}>
            <RefreshIcon
              onClick={() => fetch_data(historyFilters)}
              fontSize={"22px"}
            />
          </div>
        </div>
        <div>
          {logs?.count ? <FilterIcon onClick={toggleFilterBar} /> : null}
          <CloseIcon onClick={closeHistory} />
        </div>
      </div>
      {showFilterBar ? (
        <div className={styles.filter_menu}>
          <div className={styles.filter_menu_item}>
            {actions.length > 1 ? (
              <Select
                value={""}
                sx={{
                  fontSize: 14,
                  width: 125,
                  height: 30,
                  padding: 0,
                  alignSelf: "center",
                }}
                onChange={(e) => {
                  setHistoryFilters({
                    ...historyFilters,
                    action: e.target.value,
                  });
                }}
              >
                {actions.map((o) => (
                  <MenuItem key={o} value={o}>
                    {o}
                  </MenuItem>
                ))}
              </Select>
            ) : null}
          </div>
          <div className={styles.filter_menu_item}>
            <Select
              value={""}
              sx={{
                fontSize: 14,
                width: 125,
                height: 30,
                padding: 0,
                alignSelf: "center",
              }}
              onChange={(e) => {
                console.log(e.target);
                setHistoryFilters({
                  ...historyFilters,
                  user: e.target.value,
                });
              }}
              placeholder={"shite"}
            >
              {users.map((o) => (
                <MenuItem
                  key={o.label}
                  value={o.value}
                  sx={{ textOverflow: "ellipsis" }}
                >
                  {o.label}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      ) : null}
      <div className={styles.filter_wrapper}>
        {historyFilters.field_id ? (
          <div className={styles.filter_tag}>
            Field: {historyFilters.field_id}
            <CloseIcon
              fontSize={"18px"}
              onClick={() => {
                let _historyFilters = { ...historyFilters };
                delete _historyFilters.field_id;
                setHistoryFilters(_historyFilters);
              }}
            />
          </div>
        ) : null}
        {historyFilters.action ? (
          <div className={styles.filter_tag}>
            {historyFilters.action}
            <CloseIcon
              fontSize={"18px"}
              onClick={() => {
                let _historyFilters = { ...historyFilters };
                delete _historyFilters.action;
                setHistoryFilters(_historyFilters);
              }}
            />
          </div>
        ) : null}
        {historyFilters.user ? (
          <div className={styles.filter_tag}>
            {historyFilters.user.name}
            <CloseIcon
              fontSize={"18px"}
              onClick={() => {
                let _historyFilters = { ...historyFilters };
                delete _historyFilters.user;
                setHistoryFilters(_historyFilters);
              }}
            />
          </div>
        ) : null}
      </div>
      {(logs?.rows?.length && logs?.rows?.map((log) => render_log(log))) ||
        null}
      {logs?.rows?.length === 0 && (
        <div className={styles.no_history}>No History</div>
      )}
    </div>
  );
}
