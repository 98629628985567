import RadioGroup from "ui/crfs/radio_group";

import Year from "ui/crfs/year";
import Select from "ui/crfs/select";
import Time from "ui/crfs/time";
import Date from "ui/crfs/date";
import DropDown from "ui/crfs/drop_down";
import Text from "ui/crfs/text";
import Int from "ui/crfs/int";
import Decimal from "ui/crfs/decimal";
import BloodPressure from "ui/crfs/blood_pressure";
import DateTime from "ui/crfs/date_time";
import Heading from "ui/crfs/heading";
import Weight from "ui/crfs/weight";
import BloodPressureDateTime from "ui/crfs/blood_pressure_date_time";
import BloodTestResult from "ui/crfs/blood_test_result";
import AddAnother from "ui/crfs/add_another";
import { arrayHaveCommonItems } from "util/helpers";

export const renderFieldComponent = (
  field,
  field_array,
  {
    subject_id,
    crf_id,
    setQuery,
    crf_data,
    updateCrfQueryStatus,
    form_type,
    multi_entry_crf_id,
    crfStatus,
    user_domains,
    updateFieldValue,
    linked_crf_id,
    visit_index,
    toggleHistory,
    selected_field_id,
  },
) => {
  const base_props = {
    field,
    key: field.id,
    subject_id: subject_id,
    crf_id: crf_id,
    field_id: field.id,
    options: field.options,
    title: field.label,
    uval: field.uval,
    query: field.query,
    setQuery: setQuery,
    calcUpdateToCrfStatus: updateCrfQueryStatus,
    enableQuery: true,
    form_type,
    multi_entry_crf_id,
    crf_name: (crf_data && crf_data.name && crf_data.name.toUpperCase()) || "",
    crfStatus,
    read_only: !crf_data.write_permission,
    crf_slug: crf_data.slug,
    visit_index,
    toggleHistory,
    selected_audit_icon: selected_field_id === field.id,
  };

  //check for subject randomization status against domains
  if (
    field.domains &&
    !arrayHaveCommonItems(field.domains, crf_data.randomization_domains)
  ) {
    return null;
  }
  //check for user access to domain assignment
  if (field.domains && !arrayHaveCommonItems(field.domains, user_domains)) {
    return null;
  }
  if (field.display_logic) {
    if (
      field.display_logic.field_id &&
      (!field.display_logic.logic_type ||
        field.display_logic.logic_type === "equals")
    ) {
      let compared_field = field_array.find(
        (f) => f.id === parseInt(field.display_logic.field_id),
      );
      if (field.display_logic.value) {
        if (compared_field.value_obj.value !== field.display_logic.value) {
          return null;
        }
      }
    }
  }
  if (field.type === "year") {
    return (
      <Year
        {...base_props}
        onUpdate={updateFieldValue(
          field.id,
          "year",
          crf_id,
          multi_entry_crf_id,
          linked_crf_id || null,
        )}
      />
    );
  }
  if (field.type === "text") {
    return (
      <Text
        {...base_props}
        onUpdate={updateFieldValue(
          field.id,
          "text",
          crf_id,
          multi_entry_crf_id,
          linked_crf_id || null,
        )}
      />
    );
  }
  if (field.type === "radio") {
    return (
      <RadioGroup
        {...base_props}
        onUpdate={updateFieldValue(
          field.id,
          "radio",
          crf_id,
          multi_entry_crf_id,
          linked_crf_id || null,
        )}
        orientation={field.orientation || ""}
      />
    );
  }
  if (field.type === "select") {
    return (
      <Select
        {...base_props}
        onUpdate={updateFieldValue(
          field.id,
          "select",
          crf_id,
          multi_entry_crf_id,
          linked_crf_id || null,
        )}
      />
    );
  }
  if (field.type === "time") {
    return (
      <Time
        {...base_props}
        onUpdate={updateFieldValue(
          field.id,
          "time",
          crf_id,
          multi_entry_crf_id,
          linked_crf_id || null,
        )}
      />
    );
  }
  if (field.type === "date") {
    return (
      <Date
        {...base_props}
        onUpdate={updateFieldValue(
          field.id,
          "date",
          crf_id,
          multi_entry_crf_id,
          linked_crf_id || null,
        )}
      />
    );
  }
  if (field.type === "datetime") {
    return (
      <DateTime
        {...base_props}
        onUpdate={updateFieldValue(
          field.id,
          "datetime",
          crf_id,
          multi_entry_crf_id,
          linked_crf_id || null,
        )}
      />
    );
  }
  if (field.type === "dropdown") {
    return (
      <DropDown
        {...base_props}
        onUpdate={updateFieldValue(
          field.id,
          "dropdown",
          crf_id,
          multi_entry_crf_id,
          linked_crf_id || null,
        )}
      />
    );
  }
  if (field.type === "int") {
    return (
      <Int
        {...base_props}
        length={field.max_length || 2}
        onUpdate={updateFieldValue(
          field.id,
          "int",
          crf_id,
          multi_entry_crf_id,
          linked_crf_id || null,
        )}
        placeholder={field.placeHolder}
      />
    );
  }
  if (field.type === "weight") {
    return (
      <Weight
        {...base_props}
        length={field.max_length || 2}
        onUpdate={updateFieldValue(
          field.id,
          "weight",
          crf_id,
          multi_entry_crf_id,
          linked_crf_id || null,
        )}
        placeholder={field.placeHolder}
      />
    );
  }
  if (field.type === "decimal") {
    return (
      <Decimal
        {...base_props}
        whole_length={field.whole_length}
        decimal_length={field.decimal_length}
        units={field.units}
        onUpdate={updateFieldValue(
          field.id,
          "decimal",
          crf_id,
          multi_entry_crf_id,
          linked_crf_id || null,
        )}
      />
    );
  }
  if (field.type === "blood_pressure") {
    return (
      <BloodPressure
        {...base_props}
        onUpdate={updateFieldValue(
          field.id,
          "blood_pressure",
          crf_id,
          multi_entry_crf_id,
          linked_crf_id || null,
        )}
      />
    );
  }
  if (field.type === "heading") {
    return (
      <Heading
        {...base_props}
        position={field.position}
        color={field.color}
        onUpdate={updateFieldValue(
          field.id,
          "blood_pressure",
          crf_id,
          multi_entry_crf_id,
          linked_crf_id || null,
        )}
      />
    );
  }
  if (field.type === "blood_pressure_date_time") {
    return (
      <BloodPressureDateTime
        {...base_props}
        onUpdate={updateFieldValue(
          field.id,
          "blood_pressure_date_time",
          crf_id,
          multi_entry_crf_id,
          linked_crf_id || null,
        )}
      />
    );
  }
  if (field.type === "blood_test_result") {
    return (
      <BloodTestResult
        {...base_props}
        onUpdate={updateFieldValue(
          field.id,
          "blood_test_result",
          crf_id,
          multi_entry_crf_id,
          linked_crf_id || null,
        )}
      />
    );
  }
  if (field.type === "add_another") {
    return <AddAnother {...base_props} field_array={field_array} />;
  }
};
