import React, { useState, useRef, useEffect } from "react";
import styles from "./text.module.css";
import { TextField } from "@mui/material";
import NotificationWidget from "ui/crfs/notification_widget";
import DividerLine from "ui/crfs/divider_line";
import CheckIcon from "@mui/icons-material/Check";
import DataCompleteChangeModal from "ui/crfs/data_complete_change_modal";
import AuditIconUI from "ui/crfs/audit_icon_ui";

export default function Text(props) {
  const {
    key,
    field,
    field_id,
    title,
    onUpdate,
    enableQuery,
    length,
    placeHolder,
    show_checkmark,
    read_only,
    crfStatus,
    multi_entry_crf_id,
    crf_id,
    toggleHistory,
    selected_audit_icon,
  } = props;

  const [valueObj, setValueObj] = useState({});

  // console.log("FIELD", multi_entry_crf_id);

  useEffect(() => {
    setValueObj(field.value_obj);
  }, [crf_id, multi_entry_crf_id, field]);

  const [showingCompleteChangeModal, setShowingCompleteChangeModal] =
    useState(false);
  const ref = useRef(null);

  const setValue = (newValue) => {
    let obj = { ...valueObj };
    obj.value = newValue;
    setValueObj(obj);
  };

  const updateValue = (newValue) => {
    if (crfStatus === "data_complete") {
      setShowingCompleteChangeModal(true);
      return;
    }
    let obj = {};
    obj.value = newValue;
    obj.value_name = field.value_name;
    setValueObj(obj);
    onUpdate(obj);
  };

  const onKeyDown = (key) => {
    if (key.keyCode === 13) {
      ref.current.blur();
    }
  };

  const onBlur = () => {
    updateValue(valueObj.value);
  };

  const closeModal = () => {
    setValue(field.value_obj.value);
    setShowingCompleteChangeModal(false);
  };
  const acceptTempStorage = () => {
    onUpdate(valueObj);
    setShowingCompleteChangeModal(false);
  };

  return (
    <div className={styles.outer_wrapper}>
      <div className={styles.input_flex}>
        <div className={styles.left_split}>
          {enableQuery ? (
            <div>
              <NotificationWidget {...props} />
            </div>
          ) : (
            <div>{title}</div>
          )}
        </div>

        <div className={styles["right-split"]}>
          {read_only || field.read_only ? (
            <React.Fragment>
              <div>{valueObj.value || "---"}</div>
              <div className={styles.audit_icon}>
                <AuditIconUI
                  onClick={() => toggleHistory({ field_id: field_id })}
                  selected={selected_audit_icon}
                />
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <TextField
                inputRef={ref}
                placeholder=""
                value={valueObj.value || ""}
                onChange={(e) => setValue(e.target.value)}
                autoComplete="off"
                onKeyDown={onKeyDown}
                onBlur={onBlur}
                sx={{
                  "& .MuiInputBase-input": {
                    padding: "5px",
                    paddingLeft: "10px",
                    textAlign: "left",
                  },
                }}
                fullWidth
                multiline={field.multiline}
              />
              <div className={styles.audit_icon}>
                <AuditIconUI
                  onClick={() => toggleHistory({ field_id: field_id })}
                  selected={selected_audit_icon}
                />
              </div>
            </React.Fragment>
          )}

          {show_checkmark ? (
            <div className={styles.check_mark}>
              <CheckIcon sx={{ color: "green" }} />
            </div>
          ) : null}
        </div>
      </div>
      <DividerLine />
      <DataCompleteChangeModal
        isOpen={showingCompleteChangeModal}
        close={closeModal}
        onYes={acceptTempStorage}
      />
    </div>
  );
}
