import React from "react";
import SmsOutlined from "@mui/icons-material/Sms";
import Check from "@mui/icons-material/Check";
import Description from "@mui/icons-material/Description";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import StartIcon from "@mui/icons-material/Start";
import Refresh from "@mui/icons-material/Refresh";
import Close from "@mui/icons-material/Close";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import FilterListIcon from "@mui/icons-material/FilterList";

import ModeCommentOutlined from "@mui/icons-material/ModeCommentOutlined";
// import WaterDropOutlinedIcon from '@mui/icons-material/WaterDropOutlined';
import styles from "./icons.module.css";

const NotifyIcon = React.forwardRef((props, ref) => (
  <ModeCommentOutlined
    {...props}
    ref={ref}
    className={styles.notify_icon}
    sx={{ fontSize: props.fontSize, lineheight: props.fontSize }}
  />
));
const ResolvedIcon = React.forwardRef((props, ref) => (
  <ModeCommentOutlined
    {...props}
    ref={ref}
    className={styles.resolved_icon}
    sx={{ fontSize: props.fontSize, lineheight: props.fontSize }}
  />
));
const RespondedIcon = React.forwardRef((props, ref) => (
  <ModeCommentOutlined
    {...props}
    ref={ref}
    className={styles.responded_icon}
    sx={{ fontSize: props.fontSize, lineheight: props.fontSize }}
  />
));
const CheckIcon = React.forwardRef((props, ref) => (
  <Check
    {...props}
    ref={ref}
    className={styles.check_icon}
    sx={{ fontSize: props.fontSize, lineheight: props.fontSize }}
  />
));
const DataIcon = React.forwardRef((props, ref) => (
  <Description
    {...props}
    ref={ref}
    className={styles.data_icon}
    sx={{ fontSize: props.fontSize }}
  />
));
const ArrowBackIcon = React.forwardRef((props, ref) => (
  <KeyboardBackspaceIcon
    {...props}
    ref={ref}
    className={styles.arrow_back_icon}
    sx={{ fontSize: props.fontSize }}
  />
));
const MenuOpenIcon = React.forwardRef((props, ref) => (
  <StartIcon
    {...props}
    ref={ref}
    className={styles.menu_open}
    sx={{ fontSize: props.fontSize }}
  />
));
const RefreshIcon = React.forwardRef((props, ref) => (
  <Refresh
    {...props}
    ref={ref}
    className={styles.refresh_icon}
    sx={{ fontSize: props.fontSize }}
  />
));
const CloseIcon = React.forwardRef((props, ref) => (
  <Close
    {...props}
    ref={ref}
    className={styles.close_icon}
    sx={{ fontSize: props.fontSize }}
  />
));
const AuditIcon = React.forwardRef((props, ref) => (
  <FindInPageIcon
    {...props}
    ref={ref}
    className={props.selected ? styles.audit_icon_selected : styles.audit_icon}
    sx={{ fontSize: props.fontSize }}
  />
));
const FilterIcon = React.forwardRef((props, ref) => (
  <FilterListIcon
    {...props}
    ref={ref}
    className={styles.close_icon}
    sx={{ fontSize: props.fontSize }}
  />
));

export {
  NotifyIcon,
  ResolvedIcon,
  RespondedIcon,
  CheckIcon,
  DataIcon,
  ArrowBackIcon,
  MenuOpenIcon,
  RefreshIcon,
  CloseIcon,
  AuditIcon,
  FilterIcon,
};
