import { useEffect, useState, useRef, Fragment } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { get, put, post } from "util/requests";
import styles from "./crf_builder.module.css";
import crf_styles from "../edc/components/crf/crf.module.css";
import { TextField } from "@mui/material";
import { Select, Checkbox } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import CheckIcon from "@mui/icons-material/Check";
import { cloneDeep } from "lodash";
import { decode } from "html-entities";
import DomainSelectModal from "ui/modals/domain_select";

function EditableText(props) {
  const { text = null, onChange, width = 400, placeholder = "" } = props;
  const [isEdit, setIsEdit] = useState(false);
  const [localText, setLocalText] = useState(text || placeholder);
  const ref = useRef(null);

  const parseTextFromHtml = (html) => {
    console.log(html);

    let text = new DOMParser().parseFromString(html, "text/html")
      .documentElement.textContent;
    text = decode(text, { level: "html5" });
    console.log(text);
    text = text.replaceAll("\n", "").replaceAll("\t", "");
    return text;
  };
  if (isEdit)
    return (
      <div className={styles.editable_text}>
        <TextField
          placeHolder={placeholder}
          multiline
          autoFocus
          ref={ref}
          value={localText}
          onChange={(e) => {
            setLocalText(parseTextFromHtml(e.target.value));
            // onChange(parseTextFromHtml(e.target.value));
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setLocalText(parseTextFromHtml(e.target.value));
              setIsEdit(false);
              onChange(parseTextFromHtml(e.target.value));
            }
          }}
          onBlur={(e) => {
            setLocalText(parseTextFromHtml(e.target.value));
            setIsEdit(false);
            onChange(parseTextFromHtml(e.target.value));
          }}
          autoComplete="off"
          sx={{
            "& .MuiInputBase-input": {
              padding: "5px",
              textAlign: "center",
              width: `${width}px`,
              fontSize: 14,
            },
          }}
          onFocus={(e) => {
            e.target.select();
          }}
        />
      </div>
    );
  else {
    return (
      <div className={styles.editable_text}>
        <div
          className={styles.internal_editable_text_field}
          onChange={(e) =>
            setLocalText(parseTextFromHtml(e.currentTarget.innerHTML))
          }
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setLocalText(parseTextFromHtml(e.currentTarget.innerHTML));
              setIsEdit(false);
              onChange(e.currentTarget.innerHTML);
            }
          }}
          onBlur={(e) => {
            setLocalText(parseTextFromHtml(e.currentTarget.innerHTML));
            setIsEdit(false);
          }}
          onClick={() => {
            setIsEdit(true);
          }}
        >
          {localText}
        </div>
      </div>
    );
  }
}

function VariableName(props) {
  const { onChange, value, field_name } = props;
  return (
    <div className={styles.variable_name_row}>
      <div>{field_name}</div>
      <EditableText
        text={value}
        onChange={onChange}
        width={100}
        placeholder="placeholder"
      />
    </div>
  );
}

function Selections(props) {
  const { field, setMainCrfOptions, updateField } = props;
  const [options, setOptions] = useState(field.options || []);

  const onKeyDown = (key) => {
    if (key.keyCode === 13) {
      setMainCrfOptions(field.id, options);
    }
  };

  const modifyOptionLabel = (index, value) => {
    let opt = cloneDeep(options);
    opt[index].label = value;
    setOptions(opt);
    setMainCrfOptions(field.id, opt);
  };

  const modifyOptionValue = (index, value) => {
    let opt = cloneDeep(options);
    opt[index].value = value;
    setOptions(opt);
    setMainCrfOptions(field.id, opt);
  };

  const modifyOptionFreeText = (index, value) => {
    let opt = cloneDeep(options);
    opt[index].free_text = value;
    setOptions(opt);
    setMainCrfOptions(field.id, opt);
  };

  const setUnits = (units) => {
    let _field = cloneDeep(field);
    _field.units = units;
    updateField(_field.id, _field);
  };

  const setMaxLength = (length) => {
    let _field = cloneDeep(field);
    _field.max_length = length;
    updateField(_field.id, _field);
  };

  const setMultiline = (multiline) => {
    let _field = cloneDeep(field);
    _field.multiline = multiline;
    updateField(_field.id, _field);
  };

  const setWholeLength = (length) => {
    let _field = cloneDeep(field);
    _field.whole_length = parseInt(length);
    updateField(_field.id, _field);
  };

  const setDecimalLength = (length) => {
    let _field = cloneDeep(field);
    _field.decimal_length = parseInt(length);
    updateField(_field.id, _field);
  };

  const setFieldValue = (key, value) => {
    let _field = cloneDeep(field);
    _field[key] = value;
    updateField(_field.id, _field);
  };

  const addOptionField = () => {
    let opt = cloneDeep(options);
    opt.push({ label: "label", value: "value" });
    setOptions(opt);
  };

  const deleteOption = (index) => {
    let opt = cloneDeep(options);
    opt.splice(index, 1);
    setOptions(opt);
    setMainCrfOptions(field.id, opt);
  };

  const optionsYesNo = () => {
    let opt = [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ];
    setOptions(opt);
    setMainCrfOptions(field.id, opt);
  };

  const handleBloodTestResultData = (key, value) => {
    const _field = cloneDeep(field);
    if (!_field.options || !(_field.options.constructor === Object)) {
      _field.options = {};
    }
    if (key === "is_available") {
      _field.options.is_available = value ? true : false;
    } else if (key === "int") {
      _field.options.int_value = value ? {} : null;
    } else if (key === "decimal") {
      _field.options.decimal_value = value ? {} : null;
    } else if (key === "units") {
      if (value) {
        _field.options.units_value = [{}, {}];
      } else {
        delete _field.options.units_value;
      }
    }
    updateField(_field.id, _field);
  };

  const setBloodTestResultDataUnits = (id, label_or_value, text) => {
    const _field = cloneDeep(field);
    _field.options.units_value[id][label_or_value] = text;
    updateField(_field.id, _field);
  };

  const handleBloodTestResultIntMetaData = (key, value) => {
    const _field = cloneDeep(field);
    if (!_field.options.int_value) {
      _field.options.int_value = {};
    }
    if (key === "max_length") {
      _field.options.int_value.max_length = value;
    } else if (key === "units") {
      _field.options.int_value.units = value;
    }
    updateField(_field.id, _field);
  };

  const handleBloodTestResultDecimalMetaData = (key, value) => {
    const _field = cloneDeep(field);
    if (!_field.options.decimal_value) {
      _field.options.decimal_value = {};
    }
    if (key === "whole_length") {
      _field.options.decimal_value.whole_length = parseInt(value);
    } else if (key === "decimal_length") {
      _field.options.decimal_value.decimal_length = parseInt(value);
    } else if (key === "units") {
      _field.options.decimal_value.units = value;
    }
    updateField(_field.id, _field);
  };

  if (["radio", "select"].includes(field.type)) {
    return (
      <div>
        {/*}
        <div>
          <div>Display Organization</div>
          <Select
            value={field.orientation || ""}
            sx={{
              fontSize: 14,
              minWidth: 200,
              height: 30,
              padding: 0,
              alignSelf: "center",
            }}
            onChange={(e) => setFieldValue("orientation", e.target.value)}
          >
            {["horizontal", "vertical"].map((o) => (
              <MenuItem key={o} value={o}>
                {o}
              </MenuItem>
            ))}
          </Select>
        </div>*/}
        <div className={styles.options_row_wrapper}>
          {options.map((option, index) => {
            return (
              <div
                className={styles.options_row}
                key={`${index}-${option.label}-${option.value}`}
              >
                Label:
                <EditableText
                  text={option.label}
                  onChange={(value) => modifyOptionLabel(index, value)}
                  width={100}
                />
                Value:
                <EditableText
                  text={option.value}
                  onChange={(value) => modifyOptionValue(index, value)}
                  width={100}
                />
                <div className={styles.vertical_meta_option}>
                  <div>Free Text</div>
                  <div>
                    <Checkbox
                      checked={option.free_text || false}
                      onChange={(e) =>
                        modifyOptionFreeText(index, e.target.checked)
                      }
                    />
                  </div>
                </div>
                <div
                  onClick={() => deleteOption(index)}
                  className={styles.delete_button}
                >
                  delete
                </div>
              </div>
            );
          })}
        </div>
        <div onClick={addOptionField} className={styles.add_button}>
          Add
        </div>
        {["radio", "blood_test_result"].includes(field.type) && (
          <div onClick={optionsYesNo} className={styles.add_button}>
            Yes/No
          </div>
        )}
        {/*}
        <div>
          <Select
            value={field.free_text_last || "blank"}
            sx={{
              fontSize: 14,
              minWidth: 200,
              height: 30,
              padding: 0,
              alignSelf: "center",
            }}
            onChange={(e) => setFieldValue("free_text_last", e.target.value)}
          >
            {["blank", "free_text_last"].map((o) => (
              <MenuItem key={o} value={o}>
                {o}
              </MenuItem>
            ))}
          </Select>
        </div>*/}
      </div>
    );
  } else if (field.type === "int") {
    return (
      <div className={styles.options_row}>
        <div>
          <div>Units</div>
          <div>
            <EditableText
              text={(field && field.units) || "unit_placeholder"}
              onChange={(value) => setUnits(value)}
              width={100}
            />
          </div>
        </div>
        <div className={styles.vertical_meta_option}>
          <div>MaxLength</div>
          <div>
            <EditableText
              text={(field && field.max_length) || "length_placeholder"}
              onChange={(value) => setMaxLength(value)}
              width={100}
            />
          </div>
        </div>
      </div>
    );
  } else if (field.type === "text") {
    return (
      <div className={styles.options_row}>
        <div className={styles.vertical_meta_option}>
          <div>Multiline</div>
          <div>
            <Checkbox
              checked={field && field.multiline}
              onChange={(e) => setMultiline(e.target.checked)}
            />
          </div>
        </div>
      </div>
    );
  } else if (field.type === "decimal") {
    return (
      <div className={styles.options_row}>
        <div>
          <div>Units</div>
          <div>
            <EditableText
              text={(field && field.units) || "unit_placeholder"}
              onChange={(value) => setUnits(value)}
              width={100}
            />
          </div>
        </div>
        <div className={styles.vertical_meta_option}>
          <div>Whole Length</div>
          <div>
            <EditableText
              text={(field && field.whole_length) || "length_placeholder"}
              onChange={(value) => setWholeLength(value)}
              width={100}
            />
          </div>
        </div>
        <div className={styles.vertical_meta_option}>
          <div>Decimal Length</div>
          <div>
            <EditableText
              text={(field && field.decimal_length) || "decimal_length"}
              onChange={(value) => setDecimalLength(value)}
              width={100}
            />
          </div>
        </div>
      </div>
    );
  } else if (field.type === "blood_test_result") {
    return (
      <div>
        <div className={styles.options_row}>
          <Checkbox
            checked={field.options && field.options.is_available}
            onChange={(e) => {
              handleBloodTestResultData("is_available", e.target.checked);
            }}
          />
          result available yes/no
        </div>
        <div className={styles.options_row}>
          <div>
            <Checkbox
              checked={field.options && field.options.int_value}
              onChange={(e) => {
                handleBloodTestResultData("int", e.target.checked);
              }}
            />
            int value
          </div>
          <div className={styles.vertical_meta_option}>
            <div>MaxLength</div>
            <div>
              <EditableText
                text={
                  (field &&
                    field.options &&
                    field.options.int_value &&
                    field.options.int_value.max_length) ||
                  "length_placeholder"
                }
                onChange={(value) =>
                  handleBloodTestResultIntMetaData("max_length", value)
                }
                width={100}
              />
            </div>
          </div>
          <div>
            <div>Units</div>
            <EditableText
              text={
                (field.options &&
                  field.options.int_value &&
                  field.options.int_value.units) ||
                "unit_placeholder"
              }
              onChange={(value) =>
                handleBloodTestResultIntMetaData("units", value)
              }
              width={100}
            />
          </div>
        </div>
        <div className={styles.options_row}>
          <div>
            <Checkbox
              checked={field.options && field.options.decimal_value}
              onChange={(e) => {
                handleBloodTestResultData("decimal", e.target.checked);
              }}
            />
            decimal value
          </div>
          <div>
            <div>Units</div>
            <EditableText
              text={
                (field.options &&
                  field.options.decimal_value &&
                  field.options.decimal_value.units) ||
                "unit_placeholder"
              }
              onChange={(value) =>
                handleBloodTestResultDecimalMetaData("units", value)
              }
              width={100}
            />
          </div>
          <div>
            <div>Whole Length</div>
            <EditableText
              text={
                (field.options &&
                  field.options.decimal_value &&
                  field.options.decimal_value.whole_length) ||
                "whole_length"
              }
              onChange={(value) =>
                handleBloodTestResultDecimalMetaData("whole_length", value)
              }
              width={100}
            />
          </div>
          <div>
            <div>Decimal Length</div>
            <EditableText
              text={
                (field.options &&
                  field.options.decimal_value &&
                  field.options.decimal_value.decimal_length) ||
                "decimal_length"
              }
              onChange={(value) =>
                handleBloodTestResultDecimalMetaData("decimal_length", value)
              }
              width={100}
            />
          </div>
        </div>
        <div className={styles.options_row}>
          <div>
            <Checkbox
              checked={!!(field.options && field.options.units_value) || false}
              onChange={(e) => {
                handleBloodTestResultData("units", e.target.checked);
              }}
            />
            units display
          </div>
          <div>
            <EditableText
              text={
                (field.options &&
                  field.options.units_value &&
                  field.options &&
                  field.options.units_value[0].label) ||
                "unit1_label"
              }
              onChange={(value) =>
                setBloodTestResultDataUnits(0, "label", value)
              }
              width={100}
            />
          </div>
          <div>
            <EditableText
              text={
                (field.options &&
                  field.options.units_value &&
                  field.options &&
                  field.options.units_value[0].value) ||
                "unit1_value"
              }
              onChange={(value) =>
                setBloodTestResultDataUnits(0, "value", value)
              }
              width={100}
            />
          </div>
          <div>
            <EditableText
              text={
                (field.options &&
                  field.options.units_value &&
                  field.options &&
                  field.options.units_value[1].label) ||
                "unit2_label"
              }
              onChange={(value) =>
                setBloodTestResultDataUnits(1, "label", value)
              }
              width={100}
            />
          </div>
          <div>
            <EditableText
              text={
                (field.options &&
                  field.options.units_value &&
                  field.options &&
                  field.options.units_value[1].value) ||
                "unit2_value"
              }
              onChange={(value) =>
                setBloodTestResultDataUnits(1, "value", value)
              }
              width={100}
            />
          </div>
        </div>
      </div>
    );
  } else return null;
}

function ReadOnly(props) {
  const { field, setReadOnly } = props;
  let key = crypto.randomUUID();
  return (
    <div className={styles.read_only_row} key={`${field.key}-${field.id}`}>
      <div>Read Only:</div>
      <div>
        <Checkbox
          checked={(field && field.read_only) || false}
          onChange={(e) => {
            console.log("change");
            setReadOnly(field.id, e.target.checked);
          }}
        />
      </div>
    </div>
  );
}

function Required(props) {
  const { field, setRequired } = props;
  return (
    <div className={styles.read_only_row} key={`${field.key}-${field.id}`}>
      <div>Required:</div>
      <div>
        <Checkbox
          checked={field && field.required}
          onChange={(e) => {
            setRequired(field.id, e.target.checked);
          }}
        />
      </div>
    </div>
  );
}

function ExcludeActualOrEstimate(props) {
  const { field, setExcludeActualOrEstimate } = props;
  return (
    <div className={styles.read_only_row} key={`${field.key}-${field.id}`}>
      <div>Exclude Estimate Option:</div>
      <div>
        <Checkbox
          checked={field && field.exclude_actual_or_estimate}
          onChange={(e) => {
            setExcludeActualOrEstimate(e.target.checked);
          }}
        />
      </div>
    </div>
  );
}

function Config(props) {
  const { field, setMainCrfOptions, updateField, setReadOnly, setRequired } =
    props;
  const [options, setOptions] = useState(field.options || []);

  const setFieldValue = (key, value) => {
    let _field = cloneDeep(field);
    _field[key] = value;
    updateField(_field.id, _field);
  };

  const setPosition = (position) => {
    let _field = cloneDeep(field);
    _field.position = position;
    updateField(_field.id, _field);
  };

  if (["radio", "select"].includes(field.type)) {
    return (
      <div>
        <div>
          <div>Options Orientation</div>
          <Select
            value={field.orientation || ""}
            sx={{
              fontSize: 14,
              minWidth: 200,
              height: 30,
              padding: 0,
              alignSelf: "center",
            }}
            onChange={(e) => setFieldValue("orientation", e.target.value)}
          >
            {["horizontal", "vertical"].map((o) => (
              <MenuItem key={o} value={o}>
                {o}
              </MenuItem>
            ))}
          </Select>
        </div>
        <Required field={field} setRequired={setRequired} />
        <ReadOnly field={field} setReadOnly={setReadOnly} />
      </div>
    );
  } else if (field.type === "heading") {
    return (
      <div>
        <div>
          <div>Position</div>
          <Select
            value={field.position || ""}
            sx={{
              fontSize: 14,
              minWidth: 200,
              height: 30,
              padding: 0,
              alignSelf: "center",
            }}
            onChange={(e) => setPosition(e.target.value)}
          >
            {["left", "center"].map((o) => (
              <MenuItem key={o} value={o}>
                {o}
              </MenuItem>
            ))}
          </Select>
        </div>

        <div className={styles.vertical_meta_option}>
          <div>Color</div>
          <div>
            <Select
              value={field.color || ""}
              sx={{
                fontSize: 14,
                minWidth: 200,
                height: 30,
                padding: 0,
                alignSelf: "center",
              }}
              onChange={(e) => setFieldValue("color", e.target.value)}
            >
              {["black", "red"].map((o) => (
                <MenuItem key={o} value={o}>
                  {o}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </div>
    );
  } else if (["weight"].includes(field.type)) {
    return (
      <div>
        <Required field={field} setRequired={setRequired} />
        <ReadOnly field={field} setReadOnly={setReadOnly} />
        <ExcludeActualOrEstimate
          field={field}
          setExcludeActualOrEstimate={(value) =>
            setFieldValue("exclude_actual_or_estimate", value)
          }
        />
      </div>
    );
  } else if (
    [
      "year",
      "datetime",
      "int",
      "blood_test_result",
      "decimal",
      "text",
    ].includes(field.type)
  ) {
    return (
      <div>
        <Required field={field} setRequired={setRequired} />
        <ReadOnly field={field} setReadOnly={setReadOnly} />
      </div>
    );
  }
}

export default function CrfBuilder() {
  const { crf_id, version = "1.0" } = useParams();
  const [crf, setCrf] = useState(null);
  const [domains, setDomains] = useState([]);
  const [activeDomains, setActiveDomains] = useState([]);
  const [domainModalIsOpenData, setDomainModalIsOpenData] = useState(false);
  const study_id = useSelector((state) => state.study.active_study_id);
  const [lockedCrf, setLockedCrf] = useState(false);

  useEffect(() => {
    if (crf_id) {
      get(`/crf_design/${study_id}/${crf_id}/${version}`)
        .then((ret) => {
          if (ret.data && ret.data.locked) {
            setLockedCrf(true);
          } else if (
            (ret.data && Object.keys(ret.data).length > 0) ||
            (ret.data && ret.data.name)
          ) {
            ret.data.design.fields = ret.data.design.fields.map((field) => ({
              ...field,
              key: crypto.randomUUID(),
            }));
            setCrf(ret.data);
            if (ret.data.domains) {
              setActiveDomains(ret.data.domains);
            }
          } else {
            setCrf({ design: { fields: [] } });
          }
        })
        .catch((err) => console.log(err));
      get(`/invite_user_sites_and_roles/${study_id}`).then(({ data }) =>
        setDomains(data.domains),
      );
    }
  }, []);

  const addField = () => {
    let _crf = { ...crf };
    if (!crf.design.fields || Object.keys(crf.design.fields).length === 0) {
      _crf.design = { fields: [{ id: 1, label: "new" }] };
    } else {
      _crf.design.fields.push({
        id: crf.design.fields.slice(-1)[0].id + 1,
        label: "new",
      });
    }
    setCrf(_crf);
  };
  const options = [
    { label: "Date", value: "date" },
    { label: "Date Time", value: "datetime" },
    { label: "Time", value: "time" },
    { label: "Radio", value: "radio" },
    { label: "Select", value: "select" },
    { label: "Int", value: "int" },
    { label: "Year", value: "year" },
    { label: "Decimal", value: "decimal" },
    { label: "Blood Pressure", value: "blood_pressure" },
    { label: "Heading", value: "heading" },
    { label: "Weight", value: "weight" },
    { label: "Text Field", value: "text" },
    { label: "BP Date Time", value: "blood_pressure_date_time" },
    { label: "Blood Test Result", value: "blood_test_result" },
    { label: "Add Another", value: "add_another" },
  ];

  const changeName = (name) => {
    //changes the title of the crf
    let _crf = { ...crf };
    _crf.name = name;
    setCrf(_crf);
  };
  const changeLabel = (id, label) => {
    let _crf = { ...crf };
    let field = _crf.design.fields.find((f) => f.id === id);
    field.label = label;
    setCrf(_crf);
  };
  const changeType = (id, type) => {
    let _crf = { ...crf };
    let field = _crf.design.fields.find((f) => f.id === id);
    field.type = type;
    setCrf(_crf);
  };
  const changeVariableName = (id, variable_name, value) => {
    let _crf = { ...crf };
    let field = _crf.design.fields.find((f) => f.id === id);
    //variable name is stored in name attribute of each field
    field[variable_name] = value;
    setCrf(_crf);
  };

  const setOptions = (id, options) => {
    let _crf = cloneDeep(crf);
    console.log("options are", options);
    let field = _crf.design.fields.find((f) => f.id === id);
    field.options = options;
    setCrf(_crf);
  };

  const updateField = (id, new_field) => {
    let _crf = cloneDeep(crf);
    let field = _crf.design.fields.find((f) => f.id === id);
    Object.assign(field, new_field);
    setCrf(_crf);
  };

  const setReadOnly = (id, value) => {
    let _crf = cloneDeep(crf);
    let field = _crf.design.fields.find((f) => f.id === id);
    field.read_only = value;
    setCrf(_crf);
  };

  const setRequired = (id, value) => {
    let _crf = cloneDeep(crf);
    let field = _crf.design.fields.find((f) => f.id === id);
    field.required = value;
    setCrf(_crf);
  };

  const updatePermissions = (type, value) => {
    let _crf = cloneDeep(crf);
    if (type === "write") {
      _crf.write_permissions = value;
    } else {
      _crf.read_permissions = value;
    }
    setCrf(_crf);
  };
  const updateDomains = (id, domains) => {
    let _crf = cloneDeep(crf);
    let field = _crf.design.fields.find((f) => f.id === id);
    field.domains = domains;
    setCrf(_crf);
  };

  const saveCrf = () => {
    post("/crf_design", {
      crf_id,
      design: crf.design,
      crf,
      name: crf.name,
      study_id,
      version,
    });
  };

  const addAbove = (field_id) => {
    let _crf = cloneDeep(crf);
    _crf.design.fields.splice(field_id - 1, 0, {
      id: field_id,
      label: "new",
    });
    _crf.design.fields.forEach((field, index) => {
      field.id = index + 1;
    });
    setCrf(_crf);
  };

  const addBelow = (field_id) => {
    let _crf = cloneDeep(crf);
    _crf.design.fields.splice(field_id, 0, {
      id: field_id,
      label: "new",
    });
    _crf.design.fields.forEach((field, index) => {
      field.id = index + 1;
    });
    setCrf(_crf);
  };

  const removeField = (field_id) => {
    let _crf = cloneDeep(crf);
    // let _crf = crf;
    _crf.design.fields = _crf.design.fields.filter(
      (field) => parseInt(field.id) !== parseInt(field_id),
    );
    _crf.design.fields.forEach((field, index) => {
      field.id = index + 1;
    });
    setCrf(_crf);
  };

  const toggleDisplayLogic = (field_id) => {
    let _crf = cloneDeep(crf);
    let field = _crf.design.fields.find((field) => field.id === field_id);
    if (!field.display_logic) {
      field.display_logic = {};
    } else {
      delete field.display_logic;
    }
    setCrf(_crf);
  };

  const setDisplayIfLogic = (field_id, key, value) => {
    let _crf = cloneDeep(crf);
    let field = _crf.design.fields.find((field) => field.id === field_id);
    field.display_logic[key] = value;
    setCrf(_crf);
  };

  if (lockedCrf) {
    return (
      <div> This CRF is locked. Please contact your admin to make changes.</div>
    );
  } else if (!crf) return null;
  return (
    <div className={styles.wrapper}>
      <div className={styles.crf_builder}>CRF Builder</div>
      <div className={styles.name_wrapper}>
        <div>
          <strong>Form Title: </strong>
        </div>
        <EditableText
          text={crf.name || "new"}
          onChange={(value) => {
            changeName(value);
          }}
        />
      </div>

      {crf.design.fields &&
        crf.design.fields.map((field) => (
          <div className={styles.entry_row} key={field.key}>
            <div className={styles.field_id}>{field.id}</div>
            <div className={styles.row_select}>
              <div className={styles.type_select}>
                <div>
                  <strong>Type </strong>
                </div>
                <Select
                  value={field.type || ""}
                  sx={{
                    fontSize: 14,
                    minWidth: 200,
                    height: 30,
                    padding: 0,
                    alignSelf: "center",
                  }}
                  onChange={(e) => changeType(field.id, e.target.value)}
                >
                  {options &&
                    options.length &&
                    options.map((o) => (
                      <MenuItem key={o.value} value={o.value}>
                        {o.label}
                      </MenuItem>
                    ))}
                </Select>
              </div>
              <div className={styles.row_name}>
                <div>
                  <strong>Name </strong>
                </div>
                <EditableText
                  text={field.label}
                  onChange={(value) => {
                    changeLabel(field.id, value);
                  }}
                />
              </div>
              <div>
                <strong>Data</strong>
                <VariableName
                  field_name={"value_key:"}
                  value={field.value_name || "placeholder"}
                  onChange={(value) =>
                    changeVariableName(field.id, "value_name", value)
                  }
                />
                {[
                  "datetime",
                  "weight",
                  "blood_pressure",
                  "blood_test_result",
                  "blood_pressure_date_time",
                  "radio",
                ].includes(field.type) ? (
                  <VariableName
                    field_name={"value2_key:"}
                    value={field.value2_name || "placeholder"}
                    onChange={(value) =>
                      changeVariableName(field.id, "value2_name", value)
                    }
                  />
                ) : null}
                {[
                  "blood_test_result",
                  "weight",
                  "blood_pressure_date_time",
                ].includes(field.type) ? (
                  <VariableName
                    field_name={"value3_key:"}
                    value={field.value3_name || "placeholder"}
                    onChange={(value) =>
                      changeVariableName(field.id, "value3_name", value)
                    }
                  />
                ) : null}
                {["blood_test_result", "blood_pressure_date_time"].includes(
                  field.type,
                ) ? (
                  <VariableName
                    field_name={"value4_key:"}
                    value={field.value4_name || "placeholder"}
                    onChange={(value) =>
                      changeVariableName(field.id, "value4_name", value)
                    }
                  />
                ) : null}
                {["blood_pressure_date_time"].includes(field.type) ? (
                  <VariableName
                    field_name={"value5_key:"}
                    value={field.value5_name || "placeholder"}
                    onChange={(value) =>
                      changeVariableName(field.id, "value5_name", value)
                    }
                  />
                ) : null}
              </div>

              <div className={styles.order_buttons}>
                {" "}
                <div
                  className={styles.add_button}
                  onClick={() => addAbove(field.id)}
                >
                  add above
                </div>
                <div
                  className={styles.add_button}
                  onClick={() => addBelow(field.id)}
                >
                  add below
                </div>
                <div
                  className={styles.add_button}
                  onClick={() => removeField(field.id)}
                >
                  remove
                </div>
              </div>
            </div>
            {[
              "blood_test_result",
              "text",
              "decimal",
              "int",
              "radio",
              "select",
            ].includes(field.type) ? (
              <div className={styles.data_column}>
                <strong>Options</strong>
                <Selections
                  field={field}
                  updateField={updateField}
                  setMainCrfOptions={setOptions}
                />
              </div>
            ) : null}
            {field.type !== "add_another" ? (
              <div className={styles.data_column}>
                <strong>Config</strong>
                <Config
                  field={field}
                  updateField={updateField}
                  setMainCrfOptions={setOptions}
                  setReadOnly={setReadOnly}
                  setRequired={setRequired}
                />
              </div>
            ) : null}
            <div className={styles.data_column}>
              <strong>Logic</strong>
              <div className={styles.flex}>
                <div
                  className={styles.add_button}
                  onClick={() =>
                    setDomainModalIsOpenData({
                      id: field.id,
                      activeDomains: field.domains || [],
                    })
                  }
                >
                  domains
                </div>
                {(field.domains &&
                  field.domains.constructor === Array &&
                  field.domains.join(",")) ||
                  "all"}
              </div>
              <div
                className={styles.add_button}
                onClick={() => toggleDisplayLogic(field.id)}
              >
                display if
              </div>
              <div className={styles.flex_row}>
                {field.display_logic ? (
                  <Fragment>
                    <EditableText
                      text={
                        (field.display_logic && field.display_logic.field_id) ||
                        ""
                      }
                      placeholder="field_id"
                      onChange={(value) => {
                        setDisplayIfLogic(field.id, "field_id", value);
                      }}
                    />
                    <Select
                      value={field.display_logic?.logic_type || ""}
                      sx={{
                        fontSize: 14,
                        minWidth: 200,
                        height: 30,
                        padding: 0,
                        alignSelf: "center",
                      }}
                      onChange={(e) =>
                        setDisplayIfLogic(
                          field.id,
                          "logic_type",
                          e.target.value,
                        )
                      }
                    >
                      {[
                        { label: "equals", value: "equals" },
                        { label: "not_equals", value: "not_equals" },
                      ].map((o) => (
                        <MenuItem key={o.value} value={o.value}>
                          {o.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <EditableText
                      text={
                        (field.display_logic && field.display_logic.value) || ""
                      }
                      placeholder={"field_value"}
                      onChange={(value) => {
                        setDisplayIfLogic(field.id, "value", value);
                      }}
                    />{" "}
                  </Fragment>
                ) : null}
              </div>
            </div>
          </div>
        ))}
      <div className={styles.spacer}>
        <br />
        <br />
        <br />
      </div>
      <div className={styles.footer}>
        <div className={styles.add_button} onClick={addField}>
          Add
        </div>
        <div className={styles.add_button} onClick={saveCrf}>
          Save
        </div>
      </div>
      <DomainSelectModal
        is_open={domainModalIsOpenData}
        title={"Domains"}
        activeDomains={domainModalIsOpenData.activeDomains}
        domainList={domains}
        close={() => setDomainModalIsOpenData(false)}
        updateParent={(domains) =>
          updateDomains(domainModalIsOpenData.id, domains)
        }
      />
    </div>
  );
}
