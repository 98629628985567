import { useState, useEffect } from "react";
import NotificationWidget from "ui/crfs/notification_widget";
import DividerLine from "ui/crfs/divider_line";
import styles from "./date_time.module.css";
import DateTimeUI from "./components/date_time_ui";
import DataCompleteChangeModal from "ui/crfs/data_complete_change_modal";
import { cloneDeep } from "lodash";
import AuditIconUI from "ui/crfs/audit_icon_ui";

let tempStorage = null;

export default function DateTime(props) {
  const {
    field,
    crfStatus,
    onUpdate,
    date_position = "value",
    time_position = "value2",
    toggleHistory,
    field_id,
    selected_audit_icon,
  } = props;
  const [localVal, setLocalVal] = useState((field && field.value_obj) || {});
  const [showingCompleteChangeModal, setShowingCompleteChangeModal] =
    useState(false);

  useEffect(() => {
    setLocalVal((field && field.value_obj) || {});
  }, [field]);

  const acceptTempStorage = () => {
    onUpdate(localVal);
    setShowingCompleteChangeModal(false);
  };

  const closeModal = () => {
    setLocalVal(tempStorage);
    tempStorage = null;
    setShowingCompleteChangeModal(false);
  };

  const updateValue = (value_obj) => {
    if (crfStatus === "data_complete") {
      tempStorage = cloneDeep(localVal);
      setLocalVal(value_obj);
      setShowingCompleteChangeModal(true);
    }
    onUpdate(value_obj);
  };

  const currentValueDisplay = `${localVal.value} ${localVal.value2}`;
  return (
    <div className={styles.outer_wrapper}>
      <div className={styles.input_flex}>
        <div className={styles.left_split}>
          <NotificationWidget
            {...props}
            currentValueDisplay={currentValueDisplay}
          />
        </div>
        <div className={[styles["right-split"], styles.date_entry].join(" ")}>
          <DateTimeUI
            {...props}
            field={{ ...field, value_obj: localVal }}
            onUpdate={updateValue}
            key={props.field.id}
            date_position={"value"}
            time_position={"value2"}
          />
          <div className={styles.audit_icon}>
            <AuditIconUI
              onClick={() => toggleHistory({ field_id: field_id })}
              selected={selected_audit_icon}
            />
          </div>
        </div>
      </div>

      <DividerLine />
      <DataCompleteChangeModal
        isOpen={showingCompleteChangeModal}
        close={closeModal}
        onYes={acceptTempStorage}
      />
    </div>
  );
}
